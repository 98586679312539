<template>
  <div class="home">
    <!-- <div style="margin:40px 0 20px 0">
        <el-form :inline="true">
          <el-form-item label="责任单位">
            <el-input style="width: 180px;" placeholder="责任单位"/>
          </el-form-item>
          <el-form-item label="选择地区">
            <el-select style="width: 180px;" placeholder="选择地区"/>
            <el-select style="width: 180px;margin-left:10px" placeholder="选择街道/乡镇"/>
          </el-form-item>
          <el-form-item label="问题时间">
              <el-date-picker
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :size="size"
              />
          </el-form-item>
          <el-form-item>
            <el-button-group>
              <el-button type="primary" @click="commitFindList()">搜索</el-button>
              <el-button @click="resetSearchParam()">重置</el-button>
            </el-button-group>
          </el-form-item>
       </el-form>
    </div> -->

    <div v-if="show.showStatistic">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-card shadow="hover" class="card" style="width:100%">
            <div style="margin:0px 0px 15px 0px ;">
              <el-text style="font-size: 16px; color: #000;">点位数量</el-text>
            </div>
            <div style="font-weight: bold;font-size: 25px;">{{show.statistics.pointPositionQty}}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover" class="card" style="width:100%">
            <div style="margin:0px 0px 15px 0px ;">
              <el-text style="font-size: 16px; color: #000;">督导次数</el-text>
              <!-- 上报次数无论有无问题 -->
              <el-text style="font-size: 10px;">30天内</el-text>
            </div>
            <div style="font-weight: bold;font-size: 25px;">{{show.statistics.checkPointQty}}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover" class="card" style="width:100%">
            <div style="margin:0px 0px 15px 0px ;">
              <el-text style="font-size: 16px; color: #000;">问题数量</el-text>
              <!-- 上报问题次数 -->
              <el-text style="font-size: 10px;">30天内</el-text>
            </div>
            <div style="font-weight: bold;font-size: 25px;">{{show.statistics.problemQty}}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover" class="card" style="width:100%">
            <div style="margin:0px 0px 15px 0px ;">
              <el-text style="font-size: 16px; color: #000;">反馈次数</el-text>
              <!-- 上传整改次数 -->
              <el-text style="font-size: 10px;">30天内</el-text>
            </div>
            <div style="font-weight: bold;font-size: 25px;">{{show.statistics.feedBackProblemQty}}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover" class="card" style="width:100%">
            <div style="margin:0px 0px 15px 0px ;">
              <el-text style="font-size: 16px; color: #000;">整改中</el-text>
              <!-- 问题审核通过，未终审审核通过的问题数 -->
            </div>
            <div style="font-weight: bold;font-size: 25px;">{{show.statistics.noRectifyProblemQty}}</div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <el-card shadow="hover" class="card" style="width:100%">
            <div style="margin:0px 0px 15px 0px ;">
              <el-text style="font-size: 16px; color: #000;">整改完成</el-text>
              <!-- 审核通过的问题数 -->
            </div>
            <div style="font-weight: bold;font-size: 25px;">{{show.statistics.rectifyProblemQty}}</div>
          </el-card>
        </el-col>

        <!-- <el-row :gutter="30" style="margin-top:10px">
            <el-col :span="12">
              <el-card shadow="hover" style="width:93%">
                <div id="chart1" style="width: 100%; height:200px"></div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-button-group style="position: absolute;top:10px;right: 55px;z-index: 999" v-model="chartType">
                  <el-button size="mini">整改情况</el-button>
                  <el-button size="mini">问题类型</el-button>
                  <el-button size="mini">反复情况</el-button>
                </el-button-group>
              <el-card shadow="hover" style="width:93%">
                <div id="chart2" style="width: 100%; height:200px"></div>
              </el-card>
            </el-col>
          </el-row> -->

        <el-col :span="24">
          <el-card shadow="hover" class="card" style="width:100%">
            <!-- <div id="map" style="width:100%;height:100%">
                    <CiyMap style="width: 100%;" />
              </div> -->
            <div id="MyMap" style="padding: 0; margin: 0; width: 100%; height: calc(100vh - 350px)"></div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div v-else>
      <h1 style="text-align: center; width: 100%;">你好，{{ show.userInfo.nick }}。欢迎访问文明沧州 实地督导系统。</h1>
      <div>
        <el-empty description="您无权限查看统计数据" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue'
import CiyMap from '../../components/CityMap.vue'
import math from '../../utils/math'
import api from '../../apis/api'
import * as echarts from 'echarts'
import util from '../../utils/util'
import AMapLoader from '@amap/amap-jsapi-loader'
import { ElMessage, ElMessageBox } from 'element-plus'

const data = reactive({})
const mapSearchParam = reactive({
  pageNum: 1,
  pageSize: 100,
  latitudeLt: null,
  longitudeLt: null,
  latitudeGt: null,
  longitudeGt: null
})
const show = reactive({
  userInfo: {},
  showStatistic: true,
  statistics:{
    
  }
})

let now = new Date()

onMounted(() => {
  // resetChart1()
  // resetChart2()
  initMap()
  setTimeout(() => {
    initPoint()
  }, 2000)
})

const funs = {
  onResizeWindow: () => {
    chart1.resize()
    chart2.resize()
  }
}
let chart1 = null
let chart2 = null
onUnmounted(() => {
  if (show.showStatistic) {
    // 移除监听窗口变化
    window.removeEventListener('resize', funs.onResizeWindow)
  }
})

const resetChart1 = () => {
  chart1 = echarts.init(document.getElementById('chart1'))
  chart1.setOption({
    title: {
      text: '部门分布',
      left: 'left'
    },
    legend: {
      orient: 'vertical',
      x: 'right',
      y: 'center'
    },
    series: [
      {
        name: 'Dept',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 1048, name: '部门1' },
          { value: 735, name: '部门2' },
          { value: 580, name: '部门3' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]

  })
}

const resetChart2 = () => {
  chart2 = echarts.init(document.getElementById('chart2'))
  chart2.setOption({
    title: {
      text: '问题分布',
      left: 'left'
    },
    legend: {
      orient: 'vertical',
      x: 'right',
      y: 'center'
    },
    series: [
      {
        name: 'Dept',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 1048, name: '部门1' },
          { value: 735, name: '部门2' },
          { value: 580, name: '部门3' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]

  })
}

const initMap = () => {
  AMapLoader.load({
    key: '037a22d7572e65008c52910c9c62b0ba',
    version: '2.0',
    plugins: ['AMap.Geolocation', 'AMap.Map']
  }).then((AMap) => {
    data.AMap = AMap
    // 加载地图
    let center = [116.838715, 38.304676] // 沧州人民政府
    data.map = new data.AMap.Map('MyMap', {
      resizeEnable: true,
      center: center,
      zoom: 11
    })
    // 移动位置，获取边界，查询范围内点位
    data.map.on('moveend', () => {
      initPoint()
    })
  }).catch(e => {
    console.error(e)
  })
}

const initPoint = () => {
  const bounds = data.map.getBounds()
  const latitudeLt = bounds.northEast.lat
  const longitudeLt = bounds.northEast.lng
  const latitudeGt = bounds.southWest.lat
  const longitudeGt = bounds.southWest.lng
  mapSearchParam.latitudeLt = latitudeLt
  mapSearchParam.longitudeLt = longitudeLt
  mapSearchParam.latitudeGt = latitudeGt
  mapSearchParam.longitudeGt = longitudeGt
  mapSearchParam.pageNum = 1;
  mapSearchParam.pageSize = 100;
  setTimeout(() => {
    if (mapSearchParam.latitudeLt == latitudeLt && mapSearchParam.longitudeLt == longitudeLt && mapSearchParam.latitudeGt == latitudeGt && mapSearchParam.longitudeGt == longitudeGt) {
      for (const markerIndex in data.markers) {
        data.markers[markerIndex].setMap(null)
      }
      data.markers = []
      api.get('/backend/pointPosition/page', { params: mapSearchParam }).then(res => {
        if (res.list.length < res.total) {
          ElMessage.warning('当前区域点位较多' + res.total + '个，只显示' + res.list.length + '个，缩放拖动区域查看更多')
        }
        for (const pointIndex in res.list) {
          const point = res.list[pointIndex]
          const marker = new data.AMap.Marker({
            position: [point.longitude, point.latitude],
            title: point.name,
          })
          data.map.add(marker);
          data.markers.push(marker)
        }
      })
    }
  }, 500);
}

api.get('/backend/data/statistics', {}).then(res => {
    show.statistics = res
})

</script>

<style lang="less">
.home {
  .card {
    margin-bottom: 30px;
  }
}
</style>